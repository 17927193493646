* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: sans-serif;
}

body {
  background-color: #f0f0f0;
}

/*h1 {
  color: #000;
  text-align: center;
  text-decoration: underline;
  padding: 30px;
}

h3 {
  color: rgb(0, 0, 0);
  text-align: center;
}

h4 {
  text-align: center;
  color: rgb(0, 0, 0);
}

h5 {
  color: rgb(255, 255, 0);
}*/

/*img {
  border: 6px solid #fff;
  display: block;
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 200px;
}*/

.venue-h3 {
  text-align: center;
}

.venue-h4 {
  text-align: center;
}

.grid {
  display: grid;
  grid-gap: 16px;
  padding: 16px;
}
@media (min-width: 768px) {
  .grid {
    grid-template-columns: repeat(4, 1fr);
  }
}
.grid .col {
  background-color: white;
  margin: 16px;
  padding: 6px;
  border: thin solid #a0a096;
  border-radius: 15px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
}

/*.c1 {
  display: flex;
  height: 80px;
  width: 100%;
  background-image: linear-gradient(to bottom, rgb(255, 0, 0), #ffffff);
  align-items: center;
  justify-content: space-between;
  padding: 0 50px 0 100px;
  flex-wrap: wrap;
}

.c2 {
  display: flex;
  height: 80px;
  width: 100%;
  background-image: linear-gradient(to bottom, #ff0000, #ffffff);
  align-items: center;
  justify-content: space-between;
  padding: 0 50px 0 100px;
  flex-wrap: wrap;
}

.c3 {
  display: flex;
  height: 80px;
  width: 100%;
  background-image: linear-gradient(to bottom, #ff6000, #ffffff);
  align-items: center;
  justify-content: space-between;
  padding: 0 50px 0 100px;
  flex-wrap: wrap;
}

nav .logo {
  color: #000000;
  font-size: 35px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 25px;
}
@media (max-width: 600px) {
  nav .logo {
    flex-direction: column;
    height: 100%;
    margin-right: 40px;
  }
  .c1 {
    display: flex;
    height: 80px;
    width: 100%;
    background-image: linear-gradient(to bottom, rgb(255, 0, 0), #ffffff);
    align-items: center;
    justify-content: space-between;
    padding: 0 50px 0 100px;
    flex-wrap: wrap;
    overflow: hidden;
  }

  .c2 {
    display: flex;
    height: 80px;
    width: 100%;
    background-image: linear-gradient(to bottom, #ff0000, #ffffff);
    align-items: center;
    justify-content: space-between;
    padding: 0 50px 0 100px;
    flex-wrap: wrap;
    overflow: hidden;
  }

  .c3 {
    display: flex;
    height: 80px;
    width: 100%;
    background-image: linear-gradient(to bottom, #ff6000, #ffffff);
    align-items: center;
    justify-content: space-between;
    padding: 0 50px 0 100px;
    flex-wrap: wrap;
    overflow: hidden;
  }
}
nav ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
}
nav ul li {
  margin: 0 5px;
}
nav ul li a {
  color: #000000;
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  padding: 8px 15px;
  border-radius: 5px;
  letter-spacing: 1px;
  transition: all 0.3s ease;
}
nav ul li a.active,
nav ul li a:hover {
  color: rgb(0, 0, 0);
  background: #fff;
}
nav .menu-btn i {
  color: #fff;
  font-size: 22px;
  cursor: pointer;
  display: none;
} 

input[type="checkbox"] {
  display: none;
}

@media (max-width: 1000px) {
  nav {
    padding: 0 40px 0 50px;
  }
}
@media (max-width: 920px) {
  nav .menu-btn i {
    display: block;
  }
  #click:checked ~ .menu-btn i:before {
    content: "\f00d";
  }
  nav ul {
    position: fixed;
    top: 80px;
    left: -100%;
    background: #ff6000;
    height: 100vh;
    width: 100%;
    text-align: center;
    display: block;
    transition: all 0.3s ease;
  }
  #click:checked ~ ul {
    left: 0;
  }
  nav ul li {
    width: 100%;
    margin: 40px 0;
  }
  nav ul li a {
    width: 100%;
    margin-left: -100%;
    display: block;
    font-size: 20px;
    transition: 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }
  #click:checked ~ ul li a {
    margin-left: 0px;
  }
  nav ul li a.active,
  nav ul li a:hover {
    background: none;
    color: cyan;
  }
} */
.content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: -1;
  width: 100%;
  padding: 0 30px;
  color: #1b1b1b;
}
.content div {
  font-size: 40px;
  font-weight: 700;
}

.image {
  transition: transform 0.2s;
  width: 300px;
  height: 280px;
  margin: 0 auto;
  background-color: rgb(173, 173, 237);
  border-radius: 10px;
  border: 1px solid rgb(0, 0, 0);
}
.image:hover {
  transform: scale(1.05);
}

.input-group input:invalid {
  animation: shake 0.5s;
}

.shake {
  animation: shake 0.4s linear;
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  10%,
  90% {
    transform: translateX(-10px);
  }
  30%,
  70% {
    transform: translateX(10px);
  }
  50% {
    transform: translateX(0);
  }
}

.grid-image {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border: 6px solid #fff;
  display: block;
  max-width: 100%;
  max-height: 200px;
}

.image-text-container {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.image-text-container img {
  margin-right: 8px;
}

.image-text-container span {
  font-size: 20px; /* Set the desired text size */
}

.square-image-container {
  width: 280px;
  height: 280px;
  margin: 0 auto;
  padding: 16px; /* Add 16px of padding */
  background-color: rgb(173, 173, 237);
  background-size: cover;
  background-position: center;
  border-radius: 5px;
  border: 1px solid rgb(0, 0, 0);
  transition: transform 0.2s;
}

.square-image-container:hover {
  transform: scale(1.05);
}

.maincontainer {
  width: 300px;
  height: 300px;
  background: none;
  margin: 0 auto;
}

.thecard {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  transform-style: preserve-3d;
  transition: all 0.8s ease;
}

.thecard.is-flipped {
  transform: rotateY(180deg);
}

.thefront {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  backface-visibility: hidden;
  color: #000;
}

/* THE BACK FACE OF THE CARD, WHICH SHOWS ON MOUSEOVER */
.theback {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  backface-visibility: hidden;
  overflow: hidden;
  background: #f3f3f3;
  color: #333;
  text-align: center;
  transform: rotateY(180deg);
  border: 1px solid #000;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.app-logo-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  justify-items: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 20px;
}

.app-logo {
  width: 100px;
  height: 100px;
  object-fit: contain;
  border: none;
  transition: transform 0.2s; /* Add a transition for a smooth scaling effect */
}

.app-logo:hover {
  transform: scale(1.1);
}

.thefront h1,
.theback h1 {
  font-family: "zilla slab", sans-serif;
  padding: 30px;
  font-weight: bold;
  font-size: 24px;
  text-align: center;
}

.thefront p,
.theback p {
  font-family: "zilla slab", sans-serif;
  padding: 30px;
  font-weight: normal;
  font-size: 12px;
  text-align: center;
}

@media (max-width: 767px) {
  .square-image-container:hover,
  .image:hover,
  .app-logo:hover {
    transform: none;
  }
}
