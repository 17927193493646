.about-h2 {
  text-decoration: underline;
  margin-bottom: 20px;
}

.about-h3 {
  text-decoration: underline;
  font-size: 35px;
  margin-bottom: 20px;
}

.about-p-one {
  box-shadow: -10px -10px #777777;
  margin-inline-start: 20px;
  margin-left: 30px;
  vertical-align: middle;
  padding: 5px;
  display: inline-block;
}

.about-wrapper {
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  perspective: 10px;
}

.top {
  background-color: rgb(255, 255, 255);
  padding-top: 100px;
  padding: 50px;
  height: auto;
}

.middle {
  background-color: rgb(241, 241, 241);
  padding-top: 100px;
  padding: 50px;
  height: auto;
}

.bottom {
  background-color: rgb(255, 255, 255);
  padding-top: 100px;
  padding: 50px;
  height: auto;
}

.about-header {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  transform-style: preserve-3d;
  z-index: -1;
}

.background {
  transform: translateZ(-10px) scale(2);
}

.foreground {
  transform: translateZ(-5px) scale(1.5);
}

.background,
.foreground {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: -1;
}

.about-title {
  font-size: 7rem;
  color: white;
  text-shadow: 0 0 5px black;
}

.about-section {
  font-size: 1.5rem;
  padding: 2rem;
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
}

.container-fluid {
  padding: 60px 50px;
}

/* Media queries */
@media screen and (max-width: 768px) {
  .about-title {
    font-size: 3rem;
  }

  .about-h3 {
    font-size: 1.5rem;
  }

  .about-p-one {
    box-shadow: none;
    margin-left: 0;
    margin-inline-start: 0;
    text-align: center;
  }

  .about-p-one img {
    display: block;
    margin: 20px auto;
  }
}
