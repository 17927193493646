.addEvent-body {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  margin: 0;
  padding: 20px;
}

.addEvent-body::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../../public/images/Login/MainPageBackground.png");
  background-size: cover;
  opacity: 0.1;
  z-index: -1;
}

.addEvent-box {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: center;
}

.addEvent-h1 {
  margin-bottom: 20px;
  text-align: center;
}

.drop-zone {
  border: 2px dashed #ccc;
  padding: 20px;
  text-align: center;
  margin-bottom: 20px;
  width: 300px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.drop-zone:hover {
  transform: scale(1.01);
  cursor: pointer;
}

#eventDescription {
  width: 340px;
}

.col-right {
  margin-left: 15px;
  border: 1px solid #000000;
  border-radius: 5px;
  padding-top: 10px;
  width: 350px;
  text-align: center;
}

.addEvent-square-image-container {
  display: block;
  width: 300px;
  height: 300px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #ccc;
  background-image: url("../../../public/images/Larger_Red_Cup_Favicon.png");
  margin: 0 auto;
  border-radius: 5px;
  transition: transform 0.2s ease-in-out;
}

.addEvent-square-image-container:hover {
  transform: scale(1.05);
}

.addEvent-venue-h3,
.addEvent-venue-h4 {
  margin: 10px 0;
}

.addEvent-grid-image {
  width: 60px;
  height: 60px;
  margin-right: 5px;
  margin-left: 22px;
  border-radius: 50%;
  background-color: lightgrey;
}

.addEvent-image-text-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
  margin-left: 0;
}

@media (max-width: 768px) {
  .addEvent-box {
    flex-direction: column;
  }

  .col-right {
    margin-left: 0;
    margin-top: 15px;
  }
}
