* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.home-body {
  background-image: url("../../public/images/MainPageBackground.png");
  background-size: cover;
  overflow: auto;
  font-family: sans-serif;
  z-index: 0;
}
.login-wrapper {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.form {
  position: relative;
  width: 100%;
  max-width: 380px;
  padding: 80px 40px 40px;
  background: rgba(0, 0, 0, 0.87);
  border-radius: 10px;
  color: #fff;
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.5);
  z-index: 1;
}
.form::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background: rgba(255, 255, 255, 0.08);
  transform: skewX(-26deg);
  transform-origin: bottom left;
  border-radius: 10px;
}
.home-logo {
  background: transparent;
}
.form img {
  height: 75px;
  position: absolute;
  top: -30px;
  left: calc(50% - 40px);
  width: 75px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
}
.form h2 {
  text-align: center;
  letter-spacing: 1px;
  margin-bottom: 2rem;
  color: white;
}
.form .input-group {
  position: relative;
}
.form .input-group input {
  width: 100%;
  padding: 10px 0;
  font-size: 1rem;
  letter-spacing: 1px;
  margin-bottom: 30px;
  border: none;
  border-bottom: 1px solid #fff;
  outline: none;
  background-color: transparent;
  color: inherit;
}
.form .input-group input::placeholder {
  color: rgba(255, 255, 255, 0.7);
}
.form .input-group label {
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px 0;
  font-size: 1rem;
  transition: 0.3s ease-out;
}
.form .input-group input:focus + label,
.form .input-group input:valid + label {
  transform: translateY(-18px);
  color: #ff652f;
  font-size: 0.8rem;
}
.submit-btn {
  display: block;
  margin-left: auto;
  border: none;
  outline: none;
  background: #ff652f;
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}
.forgot-pw {
  color: inherit;
}

#forgot-pw {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  height: 0;
  z-index: 1;
  background: #fff;
  opacity: 0;
  transition: 0.6s;
}

#forgot-pw:target {
  height: 100%;
  opacity: 1;
}
.sign-up {
  color: inherit;
}

#sign-up:target {
  height: 100%;
  opacity: 1;
}

#sign-up {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  height: 0;
  z-index: 1;
  background: #fff;
  opacity: 0;
  transition: 0.6s;
}

.close {
  position: absolute;
  right: 1.5rem;
  top: 0.5rem;
  font-size: 2rem;
  font-weight: 900;
  text-decoration: none;
  color: inherit;
}

/* Style the dropdown menu */
.dropdown-menu {
  display: block;
  position: absolute;
  z-index: 1;
  list-style-type: none;
  padding: 0;
  margin: 0;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 10px;
  border: 1px solid #ccc;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
  max-height: 200px;
  overflow-y: auto;
  right: 0.5px;
  width: 380px;
}

/* Style the dropdown menu items */
.dropdown-menu li {
  display: block;
  padding: 10px;
  text-decoration: none;
  color: #fff;
}

.dropdown-menu li:hover {
  background-color: #767373;
}

/* Style the search input field */
.input-group input[type="text"] {
  width: 100%;
}

@keyframes shake {
  0%,
  100% {
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translateX(-10px);
  }
  20%,
  40%,
  60%,
  80% {
    transform: translateX(10px);
  }
}

.form.shake {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}

.form.shake h2 {
  color: red;
}

.spinner {
  margin: 5rem auto;
  text-align: center;
}

.spinner svg {
  height: 6rem;
  width: 6rem;
  fill: orange;
  animation: rotate 5s infinite linear;
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}
