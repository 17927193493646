* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.main-header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0px 10%;
  background-color: #24252a;
  position: relative;
  z-index: 1;
}

.college-logo {
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 25px;
  text-decoration: none;
}

.college-logo h3 {
  color: #edf0f1;
  font-size: 35px;
  font-weight: 600;
}

@media (max-width: 600px) {
  .college-logo h3 {
    display: none;
  }

  .college-logo {
    margin-left: 25vw;
  }
}

.logoImage {
  height: 70px;
  width: 70px;
  border: none;
  margin-top: 10px;
  margin-bottom: 10px;
}

.nav__links {
  list-style: none;
  display: flex;
}

.nav__links a,
.cta {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  color: #edf0f1;
  text-decoration: none;
}

.nav__links li {
  padding: 0px 20px;
}

.nav__links li a {
  transition: all 0.3s ease 0s;
}

.nav__links li a:hover {
  color: rgba(251, 66, 14, 0.8);
}

.c1 {
  margin-left: 20px;
  padding: 9px 25px;
  background-color: rgb(195, 26, 3);
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease 0s;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  color: #edf0f1;
  text-decoration: none;
}

.c2 {
  margin-left: 20px;
  padding: 9px 25px;
  background-color: rgba(0, 136, 169, 1);
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease 0s;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  color: #edf0f1;
  text-decoration: none;
}

.c3 {
  margin-left: 20px;
  padding: 9px 25px;
  background-color: rgba(0, 136, 169, 1);
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease 0s;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  color: #edf0f1;
  text-decoration: none;
}

.c1:hover {
  background-color: rgba(251, 66, 14, 0.8);
}
.c2:hover {
  background-color: rgba(0, 136, 169, 0.8);
}
.c3:hover {
  background-color: rgba(0, 136, 169, 0.8);
}

.main-navigation__menu-btn {
  width: 2rem;
  height: 3rem;
  background: transparent;
  border: none;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.main-navigation__menu-btn:hover {
  background-color: #343537;
}

.main-navigation__menu-btn span {
  display: block;
  width: 2rem;
  height: 2.5px;
  margin-top: 10px;
  background: #edf0f1;
}

.menu {
  display: none;
}
.profile-picture {
  border-radius: 50%;
  height: 50px;
  width: 50px;
  margin-left: 10px;
}

.header-dropdown-menu {
  position: absolute;
  right: 8.4%;
  top: 100%;
  width: 100px;
  text-align: center;
  color: rgb(255, 255, 255);
  background: rgba(0, 0, 0, 0.7);
  border-radius: 4px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
  padding: 8px 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.header-dropdown-menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.header-dropdown-menu li {
  cursor: pointer;
}

.header-dropdown-menu li:hover {
  color: #007bff;
}

@media only screen and (max-width: 800px) {
  .main-header {
    flex-direction: row-reverse;
  }
  .nav__links,
  .c1 {
    display: none;
  }
  .c2 {
    display: none;
  }
  .c3 {
    display: none;
  }
  .profile-picture {
    display: none;
  }
  .menu {
    display: initial;
  }
}
