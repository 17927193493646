/* SignupPage.module.css */

.signup-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5;
}

.signup-title {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.signup-options {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  max-width: 400px;
}

.signup-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 12px;
  font-size: 1.1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.google-btn {
  background-color: #db4437;
  color: #ffffff;
}

.microsoft-btn {
  background-color: #0078d7;
  color: #ffffff;
}

.email-btn {
  background-color: #ffffff;
  color: #333;
  border: 1px solid #999;
}

.signup-btn:hover {
  opacity: 0.8;
}

.logout-btn {
  background-color: #333;
  color: #fff;
  margin-top: 1rem;
}

.signup-link {
  text-decoration: none;
  color: #3498db;
}

.signuplink:hover {
  text-decoration: underline;
}
