/*.profile-body {
    font-family: Arial, sans-serif;
    background-color: #f8f8f8;
    margin: 0;
    padding: 0;
}*/

.profile-main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
}

.profile-account-container {
  width: 400px;
  background-color: white;
  padding: 20px;
  border: 1px solid #ddd;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.profile-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile-center-image {
  width: 100px;
  margin: 20px auto;
  margin-bottom: 0px;
}

.profile-display-name {
  margin-top: 20px;
  margin-bottom: 0; /* Add this line to remove the bottom margin */
}

.profile-small-name {
  color: grey;
  font-size: 14px;
  margin-top: 0; /* Add this line to remove the top margin */
}

.profile-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0;
}

.profile-input-field {
  width: 100%;
  margin: 10px 0;
}

.profile-input-field input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  box-sizing: border-box;
}

.profile-question {
  margin-top: 20px;
  font-weight: bold;
}

.profile-radio-options {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
}

.profile-radio-options label {
  margin-right: 2px;
  margin-left: 15px;
}
.profile-radio-options input {
  margin-left: 2px;
  margin-right: 15px;
}

.profile-button {
  background-color: #4caf50;
  color: white;
  padding: 10px;
  margin: 20px 0;
  border: none;
  cursor: pointer;
  width: 100%;
}

.profile-button:hover {
  background-color: #45a049;
}
