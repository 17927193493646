.registration-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: white;
  border-radius: 5px;
}

.registration-h1 {
  margin-bottom: 20px;
}

.registration-link {
  text-decoration: none;
  color: #3498db;
}

.registration-link:hover {
  text-decoration: underline;
}
