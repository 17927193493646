.contact-body {
  font-family: Arial, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  position: relative;
}

.contact-body::before {
  content: "";
  background-image: url("../../../public/images/Login/MainPageBackground.png");
  opacity: 0.1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background-size: cover;
  background-position: center;
}

.contact-info {
  background-color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  border-radius: 4px;
  width: 100%;
  max-width: 400px;
  text-align: center;
}

.contact-info h2 {
  margin-top: 0;
  margin-bottom: 1rem;
}

.contact-info p {
  margin-bottom: 1rem;
}

.contact-info img {
  width: 50px;
  height: 50px;
  display: block;
  margin: 0 auto 1rem;
}
