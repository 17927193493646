*,
:after,
:before {
  box-sizing: border-box;
}

.timeline-section {
  background-color: #f0f2f5;
}

/* The actual timeline (the vertical ruler) */
.main-timeline-2 {
  position: relative;
}

/* The actual timeline (the vertical ruler) */
.main-timeline-2::after {
  content: "";
  position: absolute;
  width: 3px;
  background-color: rgba(251, 66, 14, 1);
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -3px;
}

/* Container around content */
.timeline-2 {
  position: relative;
  background-color: inherit;
  width: 50%;
}

/* The circles on the timeline */
.timeline-2::after {
  content: "";
  position: absolute;
  width: 25px;
  height: 25px;
  right: -11px;
  background-color: rgba(251, 66, 14, 1);
  top: 15px;
  border-radius: 50%;
  z-index: 1;
}

/* Place the container to the left */
.left-2 {
  padding: 0px 40px 20px 0px;
  left: 0;
}

/* Place the container to the right */
.right-2 {
  padding: 0px 0px 20px 40px;
  left: 50%;
}

/* Add arrows to the left container (pointing right) */
.left-2::before {
  content: " ";
  position: absolute;
  top: 18px;
  z-index: 1;
  right: 30px;
  border: medium solid white;
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent white;
}

/* Add arrows to the right container (pointing left) */
.right-2::before {
  content: " ";
  position: absolute;
  top: 18px;
  z-index: 1;
  left: 30px;
  border: medium solid white;
  border-width: 10px 10px 10px 0;
  border-color: transparent white transparent transparent;
}

/* Fix the circle for containers on the right side */
.right-2::after {
  left: -14px;
}

.card {
  --mdb-card-spacer-y: 1.5rem;
  --mdb-card-spacer-x: 1.5rem;
  --mdb-card-title-spacer-y: 0.5rem;
  --mdb-card-border-width: 1px;
  --mdb-card-border-color: var(--mdb-border-color-translucent);
  --mdb-card-border-radius: 0.5rem;
  --mdb-card-box-shadow: 0 2px 15px -3px rgba(0, 0, 0, 0.07),
    0 10px 20px -2px rgba(0, 0, 0, 0.04);
  --mdb-card-inner-border-radius: calc(0.5rem - 1px);
  --mdb-card-cap-padding-y: 0.75rem;
  --mdb-card-cap-padding-x: 1.5rem;
  --mdb-card-cap-bg: hsla(0, 0%, 100%, 0);
  --mdb-card-bg: #fff;
  --mdb-card-img-overlay-padding: 1.5rem;
  --mdb-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--mdb-card-height);
  word-wrap: break-word;
  background-color: var(--mdb-card-bg);
  background-clip: border-box;
  border: var(--mdb-card-border-width) solid var(--mdb-card-border-color);
  border-radius: var(--mdb-card-border-radius);
  box-shadow: var(--mdb-card-box-shadow);
  margin: 20px;
}

.card-img-top {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.image-container {
  position: relative;
  width: 100%;
  padding-bottom: 70%; /* 4:3 Aspect Ratio */
  background-color: #f0f2f5; /* Optional: Add background color for better visualization */
}

.card-img,
.card-img-top {
  border-top-left-radius: var(--mdb-card-inner-border-radius);
  border-top-right-radius: var(--mdb-card-inner-border-radius);
}

.card-img,
.card-img-bottom,
.card-img-top {
  width: 100%;
  overflow: clip;
  vertical-align: middle;
}

.card-body {
  flex: 1 1 auto;
  padding: var(--mdb-card-spacer-y) var(--mdb-card-spacer-x);
  color: var(--mdb-card-color);
}

.p-4 {
  padding: 1.5rem !important;
}

.mb-0 {
  margin-bottom: 0 !important;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.text-muted {
  --mdb-text-opacity: 1;
  color: #757575 !important;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.row {
  --mdb-gutter-x: 1.5rem;
  --mdb-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--mdb-gutter-y) * -1);
  margin-right: calc(var(--mdb-gutter-x) * -0.5);
  margin-left: calc(var(--mdb-gutter-x) * -0.5);
}

/* Media queries - Responsive timeline on screens less than 600px wide */
@media screen and (max-width: 600px) {
  /* Place the timelime to the left */
  .main-timeline-2::after {
    left: 31px;
  }

  /* Full-width containers */
  .timeline-2 {
    width: 100%;
    padding-left: 70px;
    padding-right: 25px;
  }

  /* Make sure that all arrows are pointing leftwards */
  .timeline-2::before {
    left: 60px;
    border: medium solid white;
    border-width: 10px 10px 10px 0;
    border-color: transparent white transparent transparent;
  }

  /* Make sure all circles are at the same spot */
  .left-2::after,
  .right-2::after {
    left: 18px;
  }

  .left-2::before {
    right: auto;
  }

  /* Make all right containers behave like the left ones */
  .right-2 {
    left: 0%;
  }
}
